@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* font-family: 'Space Grotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', */
    /* 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Geist Variable';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-attachment: fixed;
}

.mono {
  font-family: "Geist Mono Variable";
}

.grid {
  inset: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to right, #80808012 1px, transparent 1px),
    linear-gradient(to bottom, #80808012 1px, transparent 1px);
  background-size: 24px 24px;
}

.dot {
  inset: 0;
  height: 100%;
  width: 100%;
  background-image: radial-gradient(#e5e7eb 1px, transparent 1px);
  background-size: 16px 16px;
}

@media (prefers-color-scheme: dark) {
  .dot-dark {
    inset: 0;
    height: 100%;
    width: 100%;
    background-image: radial-gradient(#ede7e71f 1px, transparent 1px);
    background-size: 16px 16px;
  }
  
  .grid-dark {
    inset: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to right, #ede7e708 1px, transparent 1px),
      linear-gradient(to bottom, #ede7e708 1px, transparent 1px);
    background-size: 24px 24px;
  }
}

